import React, { useState } from "react";
import "./Services.css";

function Services() {
  // Toggle open/close on small screens
  const [openIndex, setOpenIndex] = useState(null);

  // Simple check: if the screen is big at mount time, we show all
  const isBigScreen = window.innerWidth >= 769;

  const services = [
    {
      title: "Ad Creation",
      subtitle: "Content that clicks: we create ads that drive conversions.",
      content: (
        <>
          
With a decade of experience, we craft engaging, high-impact ads across text, image, and video—designed to captivate and drive results.
        </>
      ),
    },
    {
      title: "Campaign Management",
      subtitle: "We plan, launch, and optimise cross channel campaigns.",
      content: `We combine expert strategy with automation to ensure your ads reach the right audience. From launch to ongoing optimisation, we handle it all.`,
    },
    {
      title: "Tracking",
      subtitle: "Accuracy makes growth not guesswork.",
      content: `Whether its pixels, conversion APIs, server-side integrations or SKAN, 
        we keep up to date with the latest measurement so you don’t have to.`,
    },
    {
      title: "Analytics",
      subtitle: "From metrics to money: converting data into value.",
      content: `Beyond reporting, we identify trends that drive smarter decisions—helping you apply insights that impact your entire business.`,
    },
  ];

  const toggleService = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="services-wrapper">
      <h1 className="services-title">Our Services</h1>
      <div className="services-container">
        {services.map((service, index) => {
          // If big screen, always show content (no toggling).
          // Otherwise, only show if openIndex === index.
          const showContent = isBigScreen || openIndex === index;

          return (
            <div
              key={index}
              className={`service-card ${showContent ? "open" : ""}`}
              // On big screens, no need to toggle.
              onClick={() => !isBigScreen && toggleService(index)}
            >
              <h2 className="service-card-title">{service.title}</h2>
              <h3 className="service-card-subtitle">{service.subtitle}</h3>

              {showContent && (
                <p className="service-card-content">{service.content}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Services;