import React, { useState, useEffect } from "react";
import "./Navigation.css";

function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Function to handle link click and close the menu
  const handleLinkClick = () => {
    setIsMenuOpen(false); // Close the menu when a link is clicked
  };

  // This effect ensures the hamburger menu toggles appropriately based on screen size
  useEffect(() => {
    // Reset the menu state if the screen size is larger than 768px
    if (window.innerWidth > 768) {
      setIsMenuOpen(false); // Ensure the menu is closed on larger screens
    }
  }, []); // Only run once on mount

  // Handle window resize event for toggling menu on click
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMenuOpen(false); // Close the menu when width > 768px
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="navigation">
      {/* Be Seen Studio logo/text with a link to home */}
      <a href="#home" className="logo" onClick={handleLinkClick}>
        Be Seen Studio
      </a>

      <div className="hamburger" onClick={toggleMenu}>
        ☰ {/* Hamburger icon */}
      </div>

      <ul className={`nav-links ${isMenuOpen ? "active" : ""}`}>
        {/* Navigation Links */}
        <li>
          <a href="#services" onClick={handleLinkClick}>
            Services
          </a>
        </li>
        <li>
          <a href="#case-studies" onClick={handleLinkClick}>
            Case Studies
          </a>
        </li>
        <li>
          <a href="#about" onClick={handleLinkClick}>
            About
          </a>
        </li>
        <li>
          <a href="#faqs" onClick={handleLinkClick}>
            FAQs
          </a>
        </li>
        <li>
          <a href="#contact" onClick={handleLinkClick} className="contact-button">
            Contact Us
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Navigation;