import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function AnimateInView({ children, className }) {
  const [ref, inView] = useInView({
    threshold: 0, // Trigger as soon as any part of the element is visible
    triggerOnce: false, // Keep triggering on scroll
    rootMargin: "-50px 0px", // Adjust the trigger point slightly offscreen
  });

  const parentVariants = {
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Stagger child animations
      },
    },
    hidden: { opacity: 0 },
  };

  const childVariants = {
    visible: { opacity: 1, y: 0, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, y: 20, scale: 0.95 },
  };

  return (
    <motion.div
      ref={ref}
      className={className} // Pass CSS class for styling
      variants={parentVariants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
    >
      {React.Children.map(children, (child) => (
        <motion.div variants={childVariants}>{child}</motion.div>
      ))}
    </motion.div>
  );
}