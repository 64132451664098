import React, { useEffect } from "react";
import "./CaseStudies.css";

function CaseStudies() {
  useEffect(() => {
    const caseStudiesSection = document.querySelector(".case-studies-container");
    if (caseStudiesSection) {
      caseStudiesSection.style.minHeight = "100vh";
    }
  }, []);

  const caseStudies = [
    {
      title: "5x Lower CPA for Researchers at University of Oxford",
      image: "/images/oxford-uni.jpg",
      text:
        "We helped researchers at the University of Oxford recruit participants for a clinical psychology study by optimising existing creatives for high-impact placements like Stories and Reels. By refining audience targeting, adjusting bids, and streamlining user journeys with Lead Forms and Messenger Automation, we reduced the cost per acquisition by five times while boosting participant quality."
    },
    {
      title: "Selling out an Event for Tessellate London",
      image: "/images/Tessellate.png",
      text:
        "Tessellate London needed to sell out an event during a slow season. We upgraded their tracking with a Conversions API and transformed existing video assets into 80 optimised ad variations. This strategy resulted in over 500 ticket sales and a sold-out event with less than £200 in ad spend."
    },
  ];

  return (
    <div className="scrollable-content">
      <section className="case-studies-container">
        <h1 className="case-study-title">Recent work from Be Seen</h1>
        {caseStudies.map((study, index) => (
          <div key={index} className="case-study-item">
            <div className="case-study-left">
              <h2 className="case-study-title-text">{study.title}</h2>
              <div className="case-study-description">
                <p>{study.text}</p>
              </div>
            </div>
            <div className="case-study-right">
              <img
                className="case-study-image"
                src={study.image}
                alt={study.title}
              />
            </div>
          </div>
        ))}
      </section>
      <section className="faq-container">
        {/* FAQ content here */}
      </section>
    </div>
  );
}

export default CaseStudies;