import React, { useState, useEffect } from "react";
import "./FAQ.css";

function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    // Trigger the faq_open event when an FAQ item is opened
    if (openIndex !== index) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "faq_open",
        faqQuestion: faqContent[index].question,
      });
    }
    setOpenIndex(openIndex === index ? null : index); // Toggle the clicked FAQ item
  };

  useEffect(() => {
    const scrollableContainer = document.querySelector(".scrollable-content");

    if (openIndex !== null) {
      scrollableContainer.style.scrollSnapType = "none"; // Disable scroll-snap when an FAQ is open
    } else {
      scrollableContainer.style.scrollSnapType = "y mandatory"; // Re-enable scroll-snap when all FAQs are closed
    }

    return () => {
      scrollableContainer.style.scrollSnapType = "y mandatory"; // Reset scroll-snap on unmount
    };
  }, [openIndex]);

  const faqContent = [
    {
      question: "What happens after I fill the form?",
      answer: `We’ll reach out to discuss next steps. Usually, we’ll have a 30-minute video call to understand your goals and explain how we can help. After that, we’re ready to start running your ads.n`,
    },
    {
      question: "How do I know I’ll get results?",
      answer: `We’re so sure that you’ll be satisfied, we charge no fee for your first month. That means all money you spend goes directly on running ads. If you’re not satisfied after one month, you can walk away with no obligations. After the first month, we charge a 15% fee, meaning if you spend 2,000 on media we’ll take 300 as a fee. With the results you’ll be getting, we’re sure our fee will pay for itself.`,
    },
    {
      question: "How much should I spend?",
      answer: `We suggest a minimum of €10/day (€300/month), but there’s no maximum limit. We can scale from small budgets to millions per month across multiple platforms.`,
    },
    {
      question: "Can ads run on my page?",
      answer: `Absolutely! We’ll guide you through the quick setup to let us run ads from your page. If you don’t have one, we can set it up for you.`,
    },
  ];

  return (
    <div className="faq-container">
      <h1 className="faq-title">FAQs</h1>
      {faqContent.map((faq, index) => (
        <div
          key={index}
          className={`faq-item ${openIndex === index ? "open" : ""}`}
          onClick={() => toggleFAQ(index)}
        >
          <h2 className="faq-question">{faq.question}</h2>
          {openIndex === index && (
            <h3 className="faq-answer">{faq.answer}</h3>
          )}
        </div>
      ))}
    </div>
  );
}

export default FAQ;