import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import posthog from "posthog-js";
import AnimateInView from "./AnimateInView";
import ContactUs from "./ContactUs";
import FAQ from "./FAQ";
import CaseStudies from "./CaseStudies";
import Services from "./Services";
import Home from "./Home";
import Navigation from "./Navigation";
import PrivacyPolicy from "./PrivacyPolicy";
import About from "./About";  // Import About component
import "./App.css";
import "./Navigation.css";

// ✅ Initialize PostHog securely using environment variables
if (process.env.REACT_APP_POSTHOG_API_KEY) {
  posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST || "https://eu.i.posthog.com",
    person_profiles: "identified_only", // Change to "always" to create profiles for anonymous users
  });
}
console.log("PostHog API Key:", process.env.REACT_APP_POSTHOG_API_KEY);
console.log("PostHog Host:", process.env.REACT_APP_POSTHOG_HOST);

// ✅ Smooth Scroll Component (Original Code Preserved)
const ScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const targetId = location.hash.replace("#", "");
      const targetElement = document.getElementById(targetId);

      if (targetElement) {
        const offset = 120; // Adjust this value based on navbar height
        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY;
              const offsetPosition = elementPosition - offset;

              window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
              });

              observer.disconnect(); // Stop observing after scrolling
            }
          },
          { threshold: 0.5 }
        );

        observer.observe(targetElement);
      }
    }
  }, [location]);

  return null;
};

// ✅ Track Page Views Automatically (New PostHog Tracking)
const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    posthog.capture("$pageview", { path: location.pathname });
  }, [location]);

  return null;
};

function App() {
  return (
    <Router>
      <div className="App">
        {/* Navigation */}
        <Navigation />
        {/* Smooth Scrolling Component */}
        <ScrollToSection />
        {/* Page View Tracking */}
        <TrackPageView />
        {/* Routes */}
        <Routes>
          <Route
            path="/"
            element={
              <div className="scrollable-content">
                <section id="home">
                  <AnimateInView className="home-section">
                    <Home />
                  </AnimateInView>
                </section>

                <section id="services">
                  <AnimateInView className="services-section">
                    <Services />
                  </AnimateInView>
                </section>

                <section id="case-studies">
                  <AnimateInView className="case-studies-section">
                    <CaseStudies />
                  </AnimateInView>
                </section>

                <section id="about">
                  <AnimateInView className="about-section">
                    <About />
                  </AnimateInView>
                </section>

                <section id="faqs">
                  <AnimateInView className="faqs-section">
                    <FAQ />
                  </AnimateInView>
                </section>

                <section id="contact">
                  <AnimateInView className="contact-section">
                    <ContactUs />
                  </AnimateInView>
                </section>
              </div>
            }
          />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>
    </Router>
  );
}

// Default export
export default App;