import React from "react";
import "./Home.css";

function Home() {
  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="page-wrapper">
      {/* Gradient Background */}
      <div className="gradient-background"></div>

      {/* Header Image */}
      <img
        src={`${process.env.PUBLIC_URL}/header.png`}
        alt="Header"
        className="header-image"
      />

      {/* Home Content */}
      <div className="home-container">
        <h1>An End to End Marketing Service</h1>
        <h2>Creating, running and measuring ads that get results</h2>
      </div>

      {/* Scroll Down Arrow */}
      <div
        className="scroll-down-arrow"
        onClick={() => handleScrollToSection("case-studies")}
      ></div>
    </div>
  );
}

export default Home;