import React from "react";
import "./About.css";

function About() {
  return (
    <section className="about-section">
      {/* TOP: Title/Subheading on the left, image on the right */}
      <div className="about-top">
        <div className="about-top-left">
          <h2>About Be Seen</h2>
          <h3>Be Seen Studio is founded and operated by Aaron Lewin.</h3>
          
          {/* Add the new text below */}
          <p>
            With over a decade of experience, I’ve managed multi-million-dollar ad campaigns for brands like P&G, eBay, Coca-Cola, Bose, and Audi, as well as startups like Deliveroo and major German clients such as Rewe and Deutsche Bahn.
          </p>

          {/* Five line breaks (only show on large screens if you like) */}
          <div className="br-spacer">
            <br /><br /><br /><br /><br />
          </div>
        </div>
        <div className="about-top-right">
          <img
            className="about-image"
            src="/images/aaron.png"
            alt="Aaron Lewin"
          />
        </div>
      </div>

      {/* BOTTOM: Three columns of text spanning full width */}
      <div className="about-bottom">
        <div className="about-col">
          <h4>Performance Marketing</h4>
          <p>
            I’ve spent over a decade refining ad strategies that turn budgets into real growth. From Paid Social to Search and Display, I know what works and how to make every ad count.
          </p>
        </div>
        <div className="about-col">
          <h4>Automation & Scaling</h4>
          <p>
            I build automation systems that manage thousands of ads and conversions daily—optimising bids, audiences, and placements to maximise efficiency and scale without wasted spend.
          </p>
        </div>
        <div className="about-col">
          <h4>Creative Execution</h4>
          <p>
            I work with top designers to ensure your ads don’t just perform—they look great too. We'll strike a balance between driving conversions whilst representing your brand in the right way.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;