import React, { useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore"; // Import Firestore methods
import { initializeApp } from "firebase/app";
import "./ContactUs.css";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);
console.log("Firebase app initialized:", app);

// Initialize Firestore
const db = getFirestore(app);

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [brand, setBrand] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formStarted, setFormStarted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);

    try {
      console.log("Form data to submit:", { name, email, brand, message });
      await addDoc(collection(db, "customers"), {
        name,
        email,
        brand,
        message,
        createdAt: new Date(),
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "form_submission_success",
        formName: "Contact Form",
      });

      console.log("Form Submitted");
    } catch (error) {
      console.error("Error submitting form: ", error);
      alert("There was an error submitting the form.");
    }
  };

  const handleInputChange = (e) => {
    if (!formStarted) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "form_start",
        formName: "Contact Form",
      });
      setFormStarted(true);
    }

    const { id, value } = e.target;
    if (id === "name") setName(value);
    if (id === "email") setEmail(value);
    if (id === "brand") setBrand(value);
    if (id === "message") setMessage(value);
  };

  return (
    <div className="contact-us">
      {/* Header Section: spans full width */}
      <div className="contact-us-header">
        <h1>Contact Us</h1>
        <h3>Let's make something happen.</h3>
      </div>

      {/* Main Content Section: Two columns on large screens */}
      <div className="contact-us-wrapper">
        {/* Left Column: Contact Options (Purple Boxes) */}
        <div className="contact-options-container">
          <div className="contact-options">
            <a className="contact-option email" href="mailto:aaron@beseen.studio">
              <img src="/images/email.png" alt="Email Icon" className="option-icon" />
              <div className="option-text">
                <span className="option-title">Drop us an email</span>
                <span className="option-detail">aaron@beseen.studio</span>
              </div>
            </a>
            <a className="contact-option call" href="https://calendly.com/aaron-beseen/30min" target="_blank" rel="noopener noreferrer">
              <img src="/images/phone.png" alt="Phone Icon" className="option-icon" />
              <div className="option-text">
                <span className="option-title">Prefer a direct call?</span>
                <span className="option-detail">Book a time that works for you</span>
              </div>
            </a>
          </div>
        </div>

        {/* Right Column: The Form */}
        <div className="contact-us-container">
          <form className="contact-us-form" onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                id="name"
                type="text"
                value={name}
                onChange={handleInputChange}
                placeholder="Your Name"
                required
              />
            </div>
            <div className="input-container">
              <input
                id="email"
                type="email"
                value={email}
                onChange={handleInputChange}
                placeholder="Your Email"
                required
              />
            </div>
            <div className="input-container">
              <input
                id="brand"
                type="text"
                value={brand}
                onChange={handleInputChange}
                placeholder="Your Brand (Optional)"
              />
            </div>
            <div className="input-container">
              <textarea
                id="message"
                value={message}
                onChange={handleInputChange}
                placeholder="Tell us about your business challenge"
                rows="4"
                required
              ></textarea>
            </div>
            {isSubmitted ? (
              <div className="thank-you-message">
                <h4>Thanks, we'll be in touch!</h4>
              </div>
            ) : (
              <button type="submit">Start the conversation</button>
            )}
          </form>
        </div>
      </div>

      {/* Add Footer Image here */}
      <img src={`${process.env.PUBLIC_URL}/footer.png`} alt="Footer" className="footer-image" />    </div>
  );
}

export default ContactUs;