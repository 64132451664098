const PrivacyPolicy = () => {
    return (
        <div style={{ padding: "20px" }}>
            <h1>Privacy Policy</h1>
            <p>
                This application does not collect, process, or store any user data. It is used solely for managing and uploading assets to a personal Facebook account.
            </p>
            <p>
                Contact Us:
                <br />
                If you have any questions, please contact us at: <a href="mailto:youremail@example.com">youremail@example.com</a>
            </p>
        </div>
    );
};

export default PrivacyPolicy;